import FileSaver from 'file-saver'
import XLSX from 'xlsx'

let common = {
  outTab(id, name) {
    /* generate workbook object from table */
    let wb = XLSX.utils.table_to_book(document.querySelector('#' + id))
    if(name=='用能指标'){
      for(var i=1;i<=100;i++){
        delete wb.Sheets.Sheet1['J'+i]
      }
      for(var k=2;k<=100;k++){
        if(wb.Sheets.Sheet1['I'+k]){
          wb.Sheets.Sheet1['I'+k].v = (wb.Sheets.Sheet1['I'+k].v)*100 
        }
      }
    }
    if(name.substring(0,5)=='碳排放分析'){
      for(var j=2;j<=6;j++){
        wb.Sheets.Sheet1['D'+j].v = (wb.Sheets.Sheet1['D'+j].v)*100 
        wb.Sheets.Sheet1['F'+j].v = (wb.Sheets.Sheet1['F'+j].v)*100 
      }
    }
    /* get binary string as output */
    let wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'array'
    })
    try {
      FileSaver.saveAs(new Blob([wbout], {
        type: 'application/octet-stream'
      }), name + '.xlsx')
    } catch (e) {
      if (typeof console !== 'undefined') console.log(e, wbout)
    }
    return wbout
  },
  moveBox(box1, box2) {
    // 鼠标拖拽
    var d_box = document.getElementById(box1);
    var drop = document.getElementById(box2);
    // 鼠标在滚动条上按下 可以拖拽，鼠标移动盒子也动
    drop.onmousedown = function (event) {
      event = event || window.event;
      // 获取鼠标在页面的位置
      var pageX = event.pageX || event.clientX + document.documentElement.scrolLeft;
      var pageY = event.pageY || event.clientY + document.documentElement.scrolTop;
      // 获取鼠标按下的一瞬间在盒子中的位置
      var boxX = pageX - d_box.offsetLeft;
      var boxY = pageY - d_box.offsetTop;

      document.onmousemove = function (event) {
        event = event || window.event;
        // 鼠标在页面的位置
        var pageX = event.pageX || event.clientX + document.documentElement.scrolLeft;
        var pageY = event.pageY || event.clientY + document.documentElement.scrolTop;
        // 盒子跟着鼠标移动
        d_box.style.left = pageX - boxX + "px";
        d_box.style.top = pageY - boxY + "px";
      };
    };
    // 鼠标移开，盒子不跟着
    document.onmouseup = function () {
      document.onmousemove = null
    }
  },

  //echarts动效
  moveEcharts(lent,name){
    var currentIndex = -1
    var dataLen = lent;
    setInterval( () =>{
      // 取消之前高亮的图形
      name.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: currentIndex
      });
      currentIndex = (currentIndex + 1)%dataLen
      // 高亮当前图形
      name.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: currentIndex
      });
      // 显示 tooltip
      name.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: currentIndex
      });
    }, 5000);
  },
}

export default common